import React from 'react'
import { RichText } from '@ordaos/nucleus'

import Layout from '../layout/BasicPage'
import SEO from '../components/SEO'
import Link from '../components/Link'

const NotFoundPage = () => (
	<Layout width='wide'>
		<SEO title='404: Not found' />
		<Layout.Header title='Page Not Found' />
		<RichText>
			<p>
				This page doesn't exist. Go back to <Link to='/'>Home</Link>
			</p>
		</RichText>
	</Layout>
)

export default NotFoundPage
